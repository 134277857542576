import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { contentHeroContainer } from './index.module.scss'

const ContentHeroNew = props => (
  <div className={`${contentHeroContainer} ${props.annoucement ? 'annoucement' : ''} ${props.media ? '' : 'full-width'} ${props.className || ''}`}>
    <div className="inner-wrapper">
      <div className="content">
        {props.kicker && <span className="kicker">{props.kicker}</span>}
        <h1 className="headline">{props.headline}</h1>
        <span className="description">{props.description}</span>

        {props.actionLink || props.demoButton ? (
          <div className="action-container">
            {props.actionLink &&
              (props.actionLinkIsExternal ? (
                <a className="cta" href={props.actionLink} target={props.actionLinkInNewWindow ? '_blank' : '_self'} rel={props.actionLinkInNewWindow ? 'noreferrer' : ''}>
                  {props.actionLinkText}
                </a>
              ) : (
                <Link className="cta" to={props.actionLink}>
                  {props.actionLinkText}
                </Link>
              ))}

            {props.demoButton && (
              <Link className="secondary" to="/demo">
                Book a Demo
              </Link>
            )}
          </div>
        ) : null}
      </div>
      {props.media && <div className="media-container">{props.media}</div>}
    </div>
  </div>
)

// ContentBlockAlternating.propTypes = {
//   mediaUrl: PropTypes.string,
//   mediaAlt: PropTypes.string,
//   kicker: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.string,
//   isExternalLink: PropTypes.bool,
//   linkTo: PropTypes.string,
//   linkText: PropTypes.string,
// };

// ContentBlockAlternating.defaultProps = {
//   isExternalLink: false,
// };

export default ContentHeroNew
