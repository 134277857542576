import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../../../components/seo'
import ContentHeroNew from '../../../components/content-hero-new'
import Container from '../../../components/container'
import { infoContainer } from './index.module.scss'

const IconCheckmark = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.06066 11.8389C4.47487 12.4247 4.47487 13.3744 5.06066 13.9602L9.65685 18.5564L18.4957 9.71756C19.0815 9.13177 19.0815 8.18202 18.4957 7.59624C17.9099 7.01045 16.9602 7.01045 16.3744 7.59624L9.65685 14.3137L7.18198 11.8389C6.59619 11.2531 5.64645 11.2531 5.06066 11.8389Z"
      fill="#ffffff"
    />
  </svg>
)

const IndexPage = () => (
  <div className={infoContainer}>
    <SEO title="Basaas App" />
    <Container style={{ marginBottom: '160px' }}>
      <ContentHeroNew
        headline="We no longer support the Basaas App (EOL) - It’s the perfect time to switch to the browser"
        description="As already announced in September, we will no longer support the Basaas App as of 31th December 2021. Also the app is no longer available for installation. "
      />

      <p className="info-body">
        Some weeks ago we announced to fully focus on the Basaas browser version and the Chrome Extension. That’s why we will stop supporting the Basaas App as of <strong>31th December 2021</strong>.
        Also, you can no longer download and install the app. For you it means that it is time to switch to the browser.
      </p>

      <ul className="list">
        <li className="list-item">
          <span className="item-icon">
            <IconCheckmark />
          </span>
          <span className="item-content">
            Login to:{' '}
            <a href="https://workplace.basaas.com/" target="_blank" rel="noreferrer">
              https://workplace.basaas.com/
            </a>{' '}
            to use Basaas in your browser.
          </span>
        </li>
        <li className="list-item">
          <span className="item-icon">
            <IconCheckmark />
          </span>
          <span className="item-content">
            Install the{' '}
            <a href="https://chrome.google.com/webstore/detail/basaas-extension/dfnefpadkibojlglpobnjbphlaojobfg?utm_source=basaas-app&utm_medium=overlay" target="_blank" rel="noreferrer">
              Chrome Extension
            </a>{' '}
            to get the sidebar and password manager.{' '}
            <a href="https://help.basaas.com/hc/articles/360018800018" target="_blank" rel="noreferrer">
              Click here
            </a>{' '}
            to see a guide about the Extension
          </span>
        </li>
        <li className="list-item">
          <span className="item-icon">
            <IconCheckmark />
          </span>
          <span className="item-content">Use Basaas in your browser and benefit from web only features like Basaas Tasks and more</span>
        </li>
      </ul>

      <div className="cta-container">
        <a className="linkBtn cta" href="https://workplace.basaas.com/" target="_blank" rel="noreferrer">
          Open Workplace
        </a>
        <a className="linkBtn" href="https://www.basaas.com/blog/the-browser-is-the-real-digital-workplace" target="_blank" rel="noreferrer">
          Learn more
        </a>
      </div>

      <p className="paragraph">The app will still be usable, but no more bugs will be fixed. It is likely that with updates to your apps, more bugs will appear or the apps will stop working. </p>

      <p className="paragraph">
        If you need help migrating to the browser or have any other questions, get in touch at <a href="mailto:support@basaas.com">support@basaas.com</a>.
      </p>
    </Container>

    {/* <ContentBanner
      headline="Productivity superpowers for your browser"
      description="Check out the Basaas Chrome Extension"
      actionLinkText="Try Basaas Chrome Extension today"
      actionLinkIsExternal
      actionLinkInNewWindow
      actionLink="https://chrome.google.com/webstore/detail/basaas-extension/dfnefpadkibojlglpobnjbphlaojobfg?utm_source=basaas-com&utm_medium=banner&utm_campaign=extension&utm_content=productivity-superpowers"
      // media={<StaticImage src="../../assets/images/app-icons.svg" alt="Optimize your app management" placeholder="blurred" />}
    /> */}
  </div>
)

export default IndexPage

// export const query = graphql`
//   query {
//     placeholderLandscape: file(relativePath: { eq: "placeholder-landscape.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderPortrait: file(relativePath: { eq: "placeholder-portrait.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderSquare: file(relativePath: { eq: "placeholder-square.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }
//   }
// `
